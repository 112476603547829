<template>
  <div style="background-color: #f7f7f7;height:100%;">
    <div>
      <div class="hospitalBox">
        <img src="../../assets/touxiang.png"
          style="width: 50px; margin: 1%" />
        <div class="title"
          style="font-family: PingFang SC; font-weight: 500; margin: 1%">
          <div>姓名：{{name}}</div>
          <div>年龄：{{age}}岁</div>
        </div>
      </div>
      <div style="padding: 20px 0 0 20px">
        <div class="biankuang"></div>
        <h3 style="
            display: initial;
            padding-left: 10px;
            font-family: PingFang SC;
            font-weight: 500;
          ">
          体检记录
        </h3>
      </div>
      <!-- <div class="zhibiao"
        style="background:#fff;float: left;"
        v-for="item in bglist"
        :key="item.index">
        <div style="width: 20%;padding: 30px 20px;text-align: center;float: left;">
          <h3 style="
              display: initial;
              padding-left: 10px;
              font-family: PingFang SC;
              font-weight: 500;
            ">
            {{item.name}}
          </h3><br /><br />{{item.sex}}
        </div>
        <div style="width: 44%;padding:20px;text-align: left;float: left;">
          <h4 style="
              display: initial;
              font-family: PingFang SC;
              font-weight: 500;
            ">
            {{item.AddTime}}
          </h4>
          <p style="font-size: 14px;">{{item.type}}</p>
          <p style="font-size: 14px;margin-bottom: 0;">{{item.yiName}}</p>
        </div>
        <div class="tijianbaogao">

          <div>体<br />检<br />报<br />告</div>
        </div>
      </div> -->
      <div class="zhibiao"
        style="background:#fff;float: left;"
        v-for="item in bglist"
        :key="item.index">
        <van-collapse v-model="item.MId"
          accordion>
          <van-collapse-item :title="item.Name"
            size="large"
            :value="item.AddTime"
            name="1">
            <table border="0"
              width="100%">
              <tr>
                <td>谷丙：{{item.ALT}}</td>
                <td>谷草：{{item.AST}}</td>
                <td>总胆红素：{{item.TBil}}</td>
              </tr>
              <tr>
                <td>尿素：{{item.BUN}}</td>
                <td>肌酐：{{item.Cre}}</td>
                <td>血糖：{{item.Glu}}</td>
              </tr>
              <tr>
                <td>总胆固醇：{{item.TC}}</td>
                <td>甘油三酯：{{item.TG}}</td>
                <td>白细胞：{{item.WBC}}</td>
              </tr>
              <tr>
                <td>高密度：{{item.HDL}}</td>
                <td>低密度：{{item.LDL}}</td>
                <td>血红蛋白：{{item.HGB}}</td>
              </tr>
              <tr>
                <td>血小板：{{item.Platelet}}</td>
                <td>尿常规：{{item.Urinalysis}}</td>
                <td>心电图：{{item.ECG}}</td>
              </tr>
              <tr>
                <td>b超：{{item.Bscan}}</td>
                <td>颈彩：{{item.Neckscan}}</td>
                <td>糖化：{{item.AGES}}</td>
              </tr>
            </table>
            <span>
              备注：{{item.Remark}}
            </span>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import {
  WePagePhysicalsList,
  WeGetElderlyBaseInfo
} from "@/api/Pension";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Toast } from "vant";
export default {
  data () {
    return {
      activeName: '1',
      bglist: [],
      hId: "",
      name: "",
      age: "",
      physicalsList: "",
      OpenID: '',
    }
  },
  created () {
    this.laorenXx();
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    console.log(getOpenId())
  },
  methods: {
    laorenXx: function () {
      this.OpenID = getOpenId();
      WeGetElderlyBaseInfo({ openID: this.OpenID }).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.hId = res.data.data.HId;
          this.name = res.data.data.Name;
          this.iden = res.data.data.IdNumber;
          var birth = null;
          var myDate = new Date();
          var month = myDate.getMonth() + 1;
          var day = myDate.getDate();
          var age = 0;
          if (this.iden.length === 18) {
            age = myDate.getFullYear() - this.iden.substring(6, 10) - 1;
            birth =
              this.iden.substring(6, 10) +
              "-" +
              this.iden.substring(10, 12) +
              "-" +
              this.iden.substring(12, 14);
            if (
              this.iden.substring(10, 12) < month ||
              (this.iden.substring(10, 12) == month && this.iden.substring(12, 14) <= day)
            )
              age++;
          }
          if (this.iden.length === 15) {
            age = myDate.getFullYear() - this.iden.substring(6, 8) - 1901;
            birth =
              "19" +
              this.iden.substring(6, 8) +
              "-" +
              this.iden.substring(8, 10) +
              "-" +
              this.iden.substring(10, 12);
            if (
              this.iden.substring(8, 10) < month ||
              (this.iden.substring(8, 10) == month && this.iden.substring(10, 12) <= day)
            )
              age++;
          }
          this.age = age;
          console.log(this.age);
          this.physicals(res.data.data.HId)
        } else {
          Toast.fail(res.data.msg);
        }
      })
    },
    physicals: function (hid) {
      this.physicalsList = hid
      WePagePhysicalsList({ hId: this.physicalsList, page: 1, limit: 20 }).then((res) => {
        console.log(res.data.data);
        this.bglist = res.data.data
        console.log(res);
      })
    },
  }
}
</script>
<style scoped>
.hospitalBox {
  position: relative;
  background: #fff;
  margin-top: 10px;
}
.title {
  height: 50px;
  line-height: 50px;
  display: inline-block;
  margin: 10px;
}
.title div {
  float: left;
  margin: 0 15px;
  font-size: 1.17em;
  font-weight: 540;
}
.zhibiao {
  position: relative;
  width: 90%;
  margin: 15px 5%;
  background: #fff;
  border-radius: 15px;
}
.zhibiaoXy {
  height: 50px;
  line-height: 50px;
  display: inline-block;
  margin: 10px;
  width: 65%;
}

.time {
  right: 10px;
  position: absolute;
  font-size: 14px;
  color: #999999;
}
.biankuang {
  border: 2px solid #ff9d30ff;
  background-color: #ff9d30ff;
  width: 0;
  height: 20px;
  float: left;
  border-radius: 2px;
}
.tijianbaogao {
  width: 10%;
  text-align: center;
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0px;
}
.tijianbaogao div {
  font-size: 14px;
  background: rgb(93, 188, 255);
  border-radius: 0px 10px 10px 0px;
  color: white;
  padding: 26px 0;
}
.van-cell {
  font-size: 18px !important;
}
.value-class {
  font-size: 18px !important;
}
</style>